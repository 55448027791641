.btn-primary {
    --bs-btn-active-bg: #1d2c42;
    --bs-btn-active-border-color: #1d2c42;
    --bs-btn-active-color: #fff;
    --bs-btn-bg: #003893;
    --bs-btn-border-color: #003893;
    --bs-btn-color: #fff;
    --bs-btn-hover-bg: #667995;
    --bs-btn-hover-border-color: #667995;
    --bs-btn-hover-color: #fff;
}

.btn-secondary {
    --bs-btn-active-bg: #667995;
    --bs-btn-active-border-color: #003893;
    --bs-btn-active-color: #fff;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #003893;
    --bs-btn-color: #003893;
    --bs-btn-hover-bg: #cfdbe9;
    --bs-btn-hover-border-color: #003893;
    --bs-btn-hover-color: #003893;
}

.btn-info {
    --bs-btn-active-bg: #667995;
    --bs-btn-active-border-color: #667995;
    --bs-btn-active-color: #fff;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-color: #003893;
    --bs-btn-hover-bg: #cfdbe9;
    --bs-btn-hover-border-color: #cfdbe9;
    --bs-btn-hover-color: #003893;
}

.btn-dark {
    --bs-btn-active-bg: #000000b3;
    --bs-btn-active-border-color: #000000b3;
    --bs-btn-active-color: #fff;
    --bs-btn-bg: #000;
    --bs-btn-border-color: #000;
    --bs-btn-color: #fff;
    --bs-btn-hover-bg: #000000b3;
    --bs-btn-hover-border-color: #000000b3;
    --bs-btn-hover-color: #fff;
}

.btn-light {
    --bs-btn-active-bg: #184d9eb3;
    --bs-btn-active-border-color: #184d9eb3;
    --bs-btn-active-color: #fff;
    --bs-btn-bg: #184d9e;
    --bs-btn-border-color: #184d9e;
    --bs-btn-color: #fff;
    --bs-btn-hover-bg: #184d9eb3;
    --bs-btn-hover-border-color: #184d9eb3;
    --bs-btn-hover-color: #fff;
}
