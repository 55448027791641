@use './../bootstrap/variables' as *;

.aldryn-newsblog-latest-articles {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-top: calc(-1 * var(--bs-gutter-y));

    > * {
        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        width: 100%;
    }

    .article.article {
        margin: 3rem 0;
        width: 50%;

        @media (min-width: 992px) {
            flex: 0 0 auto;
            width: 25%;
        }
    }
}
