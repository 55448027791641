@use './../bootstrap/variables' as *;

.nav.nav-tabs {
    border: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 568px) {
        flex-direction: row;
    }

    @media (min-width: 845px) {
        align-items: center;
        flex-wrap: nowrap;
        justify-content: start;
    }

    .nav-link {
        border: 1px solid $border;
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;

        @media (min-width: 992px) {
            font-size: 1rem;
        }

        span {
            background-color: $primary;
            border-radius: 50px;
            color: $white;
            font-size: 12px;
            margin-left: 8px;
            padding: 3px 6px;
        }
    }

    li {
        > * {
            border-radius: 8px 8px 0 0;
        }

        &:first-of-type > * {
            margin-right: 20px;
        }
    }

    .nav-item {
        .nav-link {
            background-color: $light;
            color: $title;
            margin-bottom: 10px;
            width: 100%;
            z-index: 1;

            @media (min-width: 568px) {
                border-bottom: 0;
                margin-bottom: 0;
                position: relative;
                top: 1px;
                width: auto;
            }

            &.active {
                color: $dark;
                z-index: 10;
            }
        }
    }
}

.auction-nav-control {
    ul.nav-tabs {
        li.nav-item {
            button {
                span {
                    background-color: #667993;
                }
                &.active {
                    span {
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.tab-content {
    position: relative;
    z-index: 5;

    article {
        border-bottom: 1px solid $info;
        padding: 18px 0;

        aside > *:last-of-type {
            padding-bottom: 0;
        }

        > aside:first-of-type > p,
        aside aside:last-of-type > p {
            margin-bottom: 0;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .about-cards.active {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            margin-bottom: 20px;
            width: 100%;

            @media (min-width: 992px) {
                padding: 0 1rem;
                width: 33%;
            }
        }
    }

    .bg-body {
        background-color: inherit !important;
    }
}

@media (max-width: 1199px) {
    .nav.nav-tabs {
        display: flex;
        flex-wrap: nowrap;
    }

    .nav-item {
        flex-shrink: 0;
    }
}
