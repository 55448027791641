.hp-block-1 {
    * {
        position: relative;
    }

    .position-absolute {
        left: 18%;
        max-width: unset;
        top: -90px;
    }
}

.container-with-absolute-img {
    * {
        position: relative;
        z-index: 2;
    }

    .position-absolute {
        z-index: 1;
    }
}

.img-absolute-right {
    .position-absolute {
        right: -13%;
    }
}
