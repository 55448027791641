@use './../bootstrap/variables' as *;

.list-of-registrars {
    table {
        border: 1px solid $border;
        display: block;
        font-size: 0.95rem;
        overflow-x: auto;
        overflow-y: hidden;
        transition: all 0.25s ease-in-out;

        a {
            text-decoration: underline;
        }
    }

    thead {
        background-color: #f5f5f5;
        border: 1px solid border;
        color: $title;

        th {
            padding: 1rem 1rem 1rem 0;
        }


        tr {
            th:nth-of-type(1) {
                padding-left: 1rem;
            }

            @media (min-width: 1200px) {
                th:nth-of-type(1) {
                    width: 49%
                }

                th:nth-of-type(2),
                th:nth-of-type(3),
                th:nth-of-type(4) {
                    width: 1%
                }

                th:nth-of-type(6) {
                    width: 25%
                }

            }
        }
    }

    .certification {
        align-items: center;
        display: flex;
        line-height: 1;

        > *:nth-last-child(2) {
            margin: 0 0.5rem;
            padding-right: 0.5rem;
            position: relative;
            transform: translate(0px, 1px);
            width: 50px;

            &::after {
                content: url('./../img/slash.svg');
                position: absolute;
                right: 0;
            }
        }
    }

    tbody tr {
        td {
            padding: 0.5rem 2rem 0.5rem 0.25rem;
        }

        td:last-of-type {
            padding: 0;

            > *:first-child {
                margin-right: 1rem;
            }
        }

        td:nth-of-type(1) {
            padding-left: 1rem;
        }
    }

    .btn {
        font-size: 0.8rem;
        text-decoration: none;
        padding: 7px 0.8rem;
        margin: 0.25rem;
    }
}

.table-btn-show-all {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;

    > * {
        text-decoration: underline;
        cursor: pointer;
    }
}
