@use './../bootstrap/variables' as *;

.navbar-all {
    a {
        font-size: 1.15rem;
        margin: 0;
        padding: 0.85rem 1.2rem;
    }

    .logo-box a {
        padding-left: 0;
    }

    > .btn {
        flex-shrink: 0;
    }
}

.language {
    font-size: 1rem;
    list-style-type: none;
    padding: 0.85rem 0;
}

// nav desktop all pages
.page-navigation {
    color: $dark;
    margin: 0;
    padding: 0;

    @media (min-width: 992px) {
        margin: 0;
        max-width: 100%;
        padding: 0;
        text-align: right;
    }

    li {
        display: inline-block;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 11;

        @media (min-width: 1200px) {
            margin: 0 0.2rem;
            padding: 0.7rem 0;

            &.last {
                margin-right: 2.3rem;
            }
        }
    }

    a {
        color: $dark;
        cursor: pointer;
        display: inline-block;
        font-size: 1.15rem;
        padding: 2px;
        position: relative;
        text-decoration: none;
        transition: all 0.15s ease-in-out;

        @media (min-width: 992px) {
            margin: 0 1rem;
        }
    }

    .undermenu {
        background-color: $secondary;
        border-radius: 3px;
        box-shadow: 0 6px 10px 0 rgba(139, 139, 139, 0.5);
        opacity: 0;
        padding: 15px 0;
        position: absolute;
        transform: translate(-9px, 11px);
        transition: 0.15s ease-in-out;
        visibility: hidden;
        z-index: 9;

        @media (min-width: 992px) {
            border: 1px solid $secondary;
        }

        li {
            display: block;
            margin: 0;
            padding: 0;

            a {
                background-position: 23px center;
                background-repeat: no-repeat;
                background-size: auto 26px;
                box-shadow: 0 2px 0 0 $secondary;
                color: $dark;
                display: block;
                line-height: 1;
                margin: 0;
                padding: 17px 15px 14px 63px;
                transition: all 0.15s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: $secondary;
                }
            }
        }

        @media (min-width: 992px) {

            &::after,
            &::before {
                border: solid transparent;
                bottom: 100%;
                content: '';
                height: 0;
                left: 2.1rem;
                pointer-events: none;
                position: absolute;
                width: 0;
            }

            &::after {
                border-color: transparent;
                border-bottom-color: $primary;
                border-width: 5px;
                margin-left: -5px;
            }

            &::before {
                border-color: transparent;
                border-bottom-color: $secondary;
                border-width: 6px;
                margin-left: -6px;
            }
        }
    }

    li:hover .undermenu,
    li:focus .undermenu {
        opacity: 1;
        visibility: visible;
    }
}
