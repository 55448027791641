@use './../bootstrap/variables' as *;

.section-slick {
    padding: 50px 0 90px;
}

.slick-center {
    margin: auto;

    .slick-slide {
        padding: 0 12px;

        > article {
            background-color: $white;
            box-shadow: none;
            padding: 32px;
            transition: all 0.18s ease-in-out;

            @media (min-width: 1399px) {
                min-height: 395px;
            }

            * {
                margin-bottom: 0;
            }
        }
    }
}

.slick-dots {
    bottom: -70px;

    li button {
        background-color: #7b7b7b;
        height: 10px;
        width: 10px;

        &::before {
            display: none;
        }
    }

    li.slick-active button {
        background-color: $primary;
    }
}

.slick-prev,
.slick-next {
    bottom: -66px;
    top: unset;
    z-index: 100;
}

.slick-prev {
    left: calc(50% - 90px);
    left: 12px;

    &::before {
        content: url('./../img/arrow-left.svg');
    }
}

.slick-next {
    right: 12px;

    &::before {
        content: url('./../img/arrow-right.svg');
    }
}
