@use './../bootstrap/variables' as *;

.collapse-box-statistics {
    header {
        border: 1px solid $border;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 1.5rem;
        padding: 1rem 0.5rem;

        @media (min-width: 768px) {
            width: 48%;
        }

        @media (min-width: 992px) {
            margin-bottom: 2rem;
            width: 31.7%;
        }
    }

    .header-size-2 {
        width: 100%;

        @media (min-width: 992px) {
            width: 65.85%;
        }
    }

    [data-bs-toggle='collapse'][aria-expanded='true'] {
        background-color: $border;
    }

    aside {
        opacity: 0;
        transition: all 0.01s;

        > span {
            display: block;
            width: 100%;
        }

        > img {
            border: 1px solid $border;
            margin-top: 1rem;
            padding: 2rem 0.5rem;

            @media (min-width: 992px) {
                padding: 4rem 1rem;
            }
        }
    }

    aside.collapsing {
        display: flex;
    }

    .collapse.show {
        display: flex;
        opacity: 1;
    }
}
