@use './../bootstrap/variables' as *;

.commercial-services {
    @media (max-width: 767px) {
        flex-wrap: nowrap;
        overflow: auto;
    }

    > article {
        header {
            border-bottom: 10px solid transparent;
            border-image: linear-gradient(0.25turn, rgba(0, 255, 133, 1), rgba(105, 246, 255, 1));
            border-image-slice: 1;
            border-radius: 8px 8px 0 0;
            padding: 20px;

            > * {
                margin: 0;
            }
        }

        aside {
            background-color: $light;
            border-bottom: 3px solid $white;
            padding: 20px;

            > * {
                margin: 0;
                padding: 0;
                position: relative;
            }

            > p {
                margin-bottom: 0.5rem;

                img {
                    transform: translate(0, -1px);
                }
            }

            > h4 {
                img {
                    transform: translate(0, -2px);
                }
            }

            .info-tooltip {
                background-color: $white;
                border-radius: 8px;
                box-shadow: 0 4px 4px rgba(29, 44, 66, 0.16);
                font-family: 'Inter-Regular', sans-serif;
                font-size: 12px;
                font-weight: 400;
                opacity: 0;
                padding: 1rem;
                position: absolute;
                transform: scale(0.6);
                transition: all 0.15s ease-in-out;
            }

            img {
                cursor: help;
                padding: 0.5rem;
                z-index: 10;

                &:hover + .info-tooltip {
                    opacity: 1;
                    transform: scale(1);
                    z-index: 9;
                }
            }

            &:last-of-type {
                border-radius: 0 0 8px 8px;
            }
        }

        &:nth-of-type(2) {
            header {
                border-image: linear-gradient(0.25turn, rgba(105, 246, 255, 1), rgba(206, 102, 255, 1));
                border-image-slice: 1;
            }
        }

        &:nth-of-type(3) {
            header {
                border-image: linear-gradient(0.25turn, rgba(206, 102, 255, 1), rgba(91, 108, 255, 1));
                border-image-slice: 1;
            }
        }
    }

    .position-absolute {
        right: -14%;
        top: -24%;
    }
}

div[class*='col-shadow'] {
    @media (max-width: 767px) {
        height: 140px;
        padding: 0;
        position: absolute;
        right: 0;
        width: 80px;
    }

    @media (min-width: 600px) {
        right: 10%;
        width: 100px;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.col.col-shadow-white {
    @media (max-width: 767px) {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgb(255, 255, 255) 70%);
    }
}

.col.col-shadow-light {
    @media (max-width: 767px) {
        background: linear-gradient(90deg, rgba(249, 250, 251, 0), rgb(249, 250, 251) 70%);
    }
}

.commercial-services-2tabs {
    display: flex;
    flex-wrap: wrap;

    header {
        width: 100%;
    }

    aside {
        width: 100%;

        @media (min-width: 767px) {
            width: 50%;
        }

        &:nth-child(even) {
            border-right: 3px solid $white;
        }

        &:last-of-type {
            border-radius: 0 0 8px;
        }

        &:nth-last-of-type(2) {
            border-radius: 0 0 0 8px;
        }
    }
}

.col-full-shadow {
    @media (max-width: 767px) {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgb(255, 255, 255) 70%);
        height: 110%;
        padding: 0;
        position: absolute;
        right: 0;
        width: 80px;
    }

    @media (min-width: 600px) {
        right: 10%;
        width: 100px;
    }

    @media (min-width: 768px) {
        display: none;
    }
}
