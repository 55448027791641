@use "sass:math";

@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.ttf');
}

// Color system
// scss-docs-start color-variables
$primary: #003893;
$light: #f9fafb;
$dark: #1d2c42;
$title: #667995;
$white: #fff;
$border: #cfdbe9;
$secondary: #00ffc2;
$info: #ccebf5;

// scss-docs-start theme-colors-map
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'info': $info,
    'dark': $dark,
    'white': $white,
    'light': $light,
    'border': $border,
    'title': $title,
);

// Spacing
// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
    0: 0,
    1: 0.25rem,
    // 4
    2: 0.5rem,
    // 8
    3: 1rem,
    // 16
    4: $spacer * 1.5,
    // 24
    5: $spacer * 3,
    // 48
    20: 20px,
    30: 30px,
    40: 40px,
    50: 50px,
    60: 60px,
    70: 70px,
    80: 80px,
    90: 90px,
    100: 100px,
    120: 120px,
);

@function space($space) {
    @return map-get($spacers, $space);
}

// Typography
$font-family-base: Inter-Regular, sans-serif;
$font-size-base: 1rem;
$headings-line-height: 1.5;
$headings-font-weight: 700;
$headings-font-family: Inter-Bold, sans-serif;
$h1-font-size: $font-size-base * 4.625; //74
$h2-font-size: $font-size-base * 2; //32
$h3-font-size: $font-size-base * 1.375; //22
$h4-font-size: $font-size-base * 1.25; // 20
$h5-font-size: $font-size-base * 1.125; // 18
$h6-font-size: $font-size-base * 0.875; // 14

// Body
// Settings for the `<body>` element.
$body-color: $dark;
$body-bg: $white;

// Style anchor elements.
$link-color: $dark;
$link-decoration: none;

// scss-docs-start font-sizes
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);

// scss-docs-start display-headings
$display-font-sizes: (
    1: 74px,
    2: 32px,
    3: 25px,
    4: 22px,
    5: 20px,
    6: 18px,
    7: 16px,
    8: 14px,
    9: 12px,
    64: 64px,
);

// Buttons
// scss-docs-start btn-variables
$btn-color: $white;
$btn-padding-y: 1.13rem;
$btn-padding-x: 4.125rem;
$input-btn-font-family: Inter-Bold, sans-serif;
$btn-font-weight: 700;
$btn-font-size: 22px;
$btn-line-height: 1;

$btn-padding-y-sm: 0.62rem;
$btn-padding-x-sm: 2rem;
$btn-font-size-sm: 1.15rem;

$btn-padding-y-lg: 1.45rem;
$btn-padding-x-lg: 4.125rem;
$btn-font-size-lg: 24px;

$btn-border-width: 1px;
$btn-border-radius: 0.5rem;
$btn-border-radius-sm: 0.5rem;
$btn-border-radius-lg: 0.5rem;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-color: $primary;
$input-border-color: $border;
$input-border-width: 1px;
$input-box-shadow: none;
$input-border-radius: 8px;
$input-focus-width: 1px;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-placeholder-color: $title;

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 4.125rem;
$nav-link-font-size: 1rem;
$nav-link-color: $primary;
$nav-link-hover-color: $primary;

$nav-tabs-border-color: $primary;
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $primary;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: $info;
$nav-tabs-link-active-border-color: $primary;
