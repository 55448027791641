@use './../bootstrap/variables' as *;

main,
footer {
    overflow: hidden;
}

@media (min-width: 1450px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

.row {
    > * {
        @media (min-width: 1900px) {
            padding-left: 1.07rem;
            padding-right: 1.07rem;
        }
    }

    .same-height {
        padding-bottom: 1.5rem;

        @media (min-width: 1900px) {
            padding-bottom: 2.14rem;
        }
    }
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

small {
    font-size: 11px;
}

p a {
    text-decoration: underline;
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 300px;
    }
}

.button-more-with-lg {
    width: 100%;

    @media (min-width: 992px) {
        width: 300px;
    }
}


.mb-n-0 * {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

.font-400-inter,
.font-400-inter * {
    font-family: Inter-Regular, sans-serif;
    font-weight: 400;
}

strong,
.font-700-inter,
.font-700-inter * {
    font-family: Inter-Bold, sans-serif;
    font-weight: 700;

    small {
        font-size: 60%;
        font-weight: normal;
        margin-left: 0.5em;
    }
}

.w-100-img img {
    width: 100%;
}

.wm-100-img img {
    max-width: 100%;
}

.btn-xxl-width {
    min-width: 100%;

    @media (min-width: 567px) {
        min-width: 385px;
    }
}

.text-underline,
.text-underline * {
    text-decoration: underline;
}

table {
    font-size: 0.95rem;
    width: 100%;

    tr {
        border-bottom: 1px solid $info;
    }

    td {
        padding: 0.7rem 0;
    }
}

.response-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.w-xxl-auto {
    @media (min-width: 1400px) {
        width: auto !important;
    }
}

.linear-gradient1 {
    background: linear-gradient(90deg, #00252e 41.75%, #006a82 87.83%);
}

.pt-1px {
    padding-top: 1px;
}

.small-text,
.small-text * {
    font-size: 11px;
}

.z-index-2,
.z-index-2 * {
    position: relative;
    z-index: 2;
}

.btn-with-icon {
    padding: 0.6rem 1.2rem;
}

.set-for-image-absolute {
    @media (min-width: 992px) {
        min-height: 480px;
    }
}


.overlay-img-1 {
    display: flex;
    justify-content: center;
    position: relative;

    @media (min-width: 768px) {
        display: block;
    }

    img:first-of-type {
        max-width: 100%;

        @media (min-width: 768px) {
            left: 75px;
            max-width: 700px;
            min-width: 385px;
            position: absolute;
        }
    }
}


.text-20-16 {
    font-size: 16px;

    @media (min-width: 768px) {
        font-size: 20px;
    }
}

.content-info {
    p {
        font-size: 14px;
        margin-bottom: 16px;

        @media (min-width: 768px) {
            font-size: 18px;
            margin-bottom: 32px;
        }
    }

    h2 {
        margin: 1rem auto 1.5rem;
    }
}

.is-homepage .faq {
    background-color: $white !important;
}

.domain-table {
    table {
        border: 1px solid $border;

        .btn {
            font-size: 13px;
            line-height: 150%;
            margin: 0 20px;
            padding: 3px 19px;
        }

        thead {
            background-color: $light;
        }

        tbody {
            background-color: $white;
        }

        th {
            color: $title;
            font-size: 15px;
            line-height: 150%;
            padding: 2rem 0 0.5rem;

            &:first-of-type {
                padding-left: 20px;
            }
        }

        td {
            color: $dark;
            font-size: 15px;
            line-height: 150%;
            min-width: 90px;
            padding: 0.5rem 0;

            &:first-of-type {
                cursor: pointer;
                padding-left: 20px;
                text-decoration: underline;
                width: 30%;
            }

            > div.data-not-available,
            > div.no-items {
                display: inline-block;
                margin-left: 20px;
                padding: 20px 0;
                text-decoration: none;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: 10%;
            }

            &:nth-of-type(4),
            &:nth-of-type(5) {
                width: 15%;
            }

            &:nth-of-type(5) {
                width: 20%;
            }

            &:last-of-type {
                padding: 0;
                min-width: 160px;
            }
        }
    }

    .data-stamp {
        background-color: $white;
        padding: 20px 20px 0;
    }
}

.tab-pane {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.auctions-export {
    align-items: center;
    background: $white;
    border-radius: 6px;
    border: 1px solid $title;
    color: $title;
    display: inline-flex;
    font-family: Inter-Bold, sans-serif;
    font-size: 15px;
    line-height: 1;
    padding: 8px 45px 8px 15px;
    position: relative;

    &::after {
        content: url('./../img/csv.svg');
        position: absolute;
        right: 13px;
    }
}

.auction-highest-price {
    border: 1px solid $border;
    border-radius: 8px;
    font-size: 0.9rem;
    margin: 1rem auto auto;

    @media (min-width: 992px) {
        width: 80%;
    }

    thead {
        color: $title;

        tr th {
            background-color: #eff0f1;
        }
    }

    tr {
        border-bottom: 1px solid $border;

        th:nth-child(2),
        td:nth-child(2) {
            text-align: right;
        }
    }

    th,
    td {
        padding: 0.5rem 1.5rem
    }

    th {
        @media (min-width: 992px) {
            padding-top: 4rem;
        }
    }
}
