@use './../bootstrap/variables' as *;

//radio appearance
ul[id*='id_radioselect'] {
    label {
        left: 2rem;
        position: relative;
        width: calc(100% - 32px);
    }
}

.checkbox {
    input {
        position: absolute;
    }
}

input[type='radio'],
input[type='checkbox'] {
    appearance: none;
    border: 0;
    box-shadow: none;
}

input[type='radio']::before,
input[type='radio']::after,
input[type='checkbox']::before,
input[type='checkbox']::after {
    content: '';
    display: inline-block;
    left: -2rem;
    position: absolute;
    top: 4.5px;
}

input[type='radio']::before,
input[type='checkbox']::before {
    border: 1px solid $border;
    border-radius: 3px;
    height: 16px;
    width: 16px;
}

label input[type='radio']:checked::before,
label input[type='checkbox']:checked::before {
    background-color: $primary;
    border: 1px solid $primary;
}

input[type='radio']::after,
input[type='checkbox']::after {
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    height: 6px;
    transform: rotate(-45deg) translate(-1.7px, 2.6px);
    width: 13px;
}

label input[type='radio']::after,
label input[type='checkbox']::after {
    content: none;
}

label input[type='radio']:checked::after,
label input[type='checkbox']:checked::after {
    content: '';
}

.has-error {
    input[type='radio']::before,
    input[type='checkbox']::before {
        border-color: $dark;
    }

    input[type='radio'].checkbox-ok::before,
    input[type='checkbox'].checkbox-ok::before {
        border-color: $border;
    }

    label input[type='radio'].checkbox-ok:checked::before,
    label input[type='checkbox'].checkbox-ok:checked::before {
        background-color: $primary;
        border: 1px solid $primary;
    }
}

.label-hidden {
    input,
    textarea {
        background-clip: padding-box;
        box-shadow: -2px -30px 0 -2px $light;

        &:not(:placeholder-shown) {
            // border-color: $border;
            box-shadow: -2px -30px 0 -2px rgba(255, 255, 255, 0);
        }

        ul.errormessages {
            display: block;
        }

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }

        &.email-error {
            &:not(:placeholder-shown) {
                border-color: $dark;
                box-shadow: -2px -30px 0 -2px $info;
            }

            &:not(:placeholder-shown) + ul.errormessages {
                display: block;
            }
        }
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: $dark;

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }
    }
}

.label-hidden {
    display: flex;
    flex-direction: column;
    position: relative;

    ul.errormessages {
        order: 1;
        position: absolute;
        z-index: 9;
    }

    label {
        order: 2;
    }

    input,
    textarea {
        order: 3;
    }
}

.has-error {
    border-color: $dark;
    position: relative;

    .text-danger {
        position: absolute;
        top: 3rem;
    }
}

form {
    label {
        color: $primary;
        font-family: Inter-Bold, sans-serif;
        font-size: 10px;
        margin-bottom: 0.2rem;
        margin-top: 1rem;
    }

    ::placeholder {
        font-family: Inter-Regular,
            sans-serif;
    }

    abbr[title] {
        text-decoration: none;
    }

    label {
        display: block;
    }

    .d-flex aside {
        width: 100%;

        @media (min-width: 768px) {
            width: 50%;
        }
    }

    textarea {
        height: 11rem;
        width: 100%;
    }

    input {
        width: 100%;
    }

    input[type='file'] {
        border: 0;
    }
}

.checkbox {
    @media (min-width: 992px) {
        display: grid;
        grid-column-gap: 0;
        grid-row-gap: 14px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 24px);
    }

    > p {
        color: $dark;
        grid-area: 1/1/3/2;
        width: 100%;

        @media (min-width: 992px) {
            text-align: right;
        }
    }

    label {
        color: $dark;
        font-family: Inter-Regular, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;
        padding-bottom: 10px;
        padding-left: 2rem;

        @media (min-width: 992px) {
            padding-bottom: 0;
            padding-left: 10rem;

            &:first-of-type {
                grid-area: 1/2/2/3;
            }

            &:nth-of-type(2) {
                grid-area: 2/2/3/3;
            }

            &:nth-of-type(3) {
                grid-area: 3/2/4/3;
            }

            &:nth-of-type(4) {
                grid-area: 4/2/5/3;
            }

            &:nth-of-type(5) {
                grid-area: 5/2/6/3;
            }

            &:nth-of-type(6) {
                grid-area: 6/2/7/3;
            }
        }
    }
}
